import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import ItemSingle from "../components/elements/item-single"
import CustomComponent from "../components/elements/custom-component"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useGroupedComponents } from "../hooks/use-grouped-components"

const AsmetEventTemplate = ({ data }) => {
  const event = data.eventSingle

  const asmetHome = useAsmetHome()
  const groupedComponents = useGroupedComponents(event.contenido)

  const crumbs = [
    { path: `/events`, label: "Eventos" },
    { path: `/events/${event.slug}`, label: event.nombre },
  ]

  return (
    <Layout
      meta={
        event.meta
          ? { ...event.meta, og_tipo: "event" }
          : {
              og_tipo: "event",
              og_titulo: event.nombre,
              og_descripcion: event.descripcion || null,
              og_imagen: event.imagen_principal || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{event.nombre}</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="asm-page-general">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <ItemSingle
                item={{
                  id: event.strapiId,
                  titulo: event.nombre,
                  descripcion: event.descripcion,
                  imagen: event.imagen_principal,
                }}
              />
            </div>
            <div className="col-12 col-sm-8 mb-n5">
              <p className="text-gray-beta text-right">
                <Moment format="LL">{event.fecha}</Moment>
              </p>
            </div>
          </div>
        </div>

        {groupedComponents.map((item, index) => {
          return <CustomComponent component={item} index={index} key={index} pageId={event.slug} />
        })}
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetEventTemplate

export const query = graphql`
  query EventSingle($id: String!) {
    eventSingle: strapiMediaEvento(strapiId: { eq: $id }) {
      strapiId
      slug
      nombre
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      fecha
      imagen_principal {
        publicURL
      }
      descripcion

      contenido {
        id
        tipo_componente

        # Component Texto

        contenido

        # Component Documento

        titulo
        fecha_publicacion
        es_descargable
        descripcion
        archivo {
          publicURL
          extension
        }

        # Component Banner

        slides {
          tipo_componente
          titulo
          subtitulo
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Card

        imagen {
          publicURL
        }
        link {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Grupo Cards

        cards {
          tipo_componente
          titulo
          descripcion
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }
      }
    }
  }
`
